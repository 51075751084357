<template>
  <basic-page-layout>
    <template v-if="regionData">
    <ion-grid class="tour-grid">
      <ion-row class="ion-align-items-stretch" v-if="regionData['epapp:tours'] && regionData['epapp:tours'].length">
        <ion-col color="tour" size="12" size-md="6"
                 v-for="(tour, key) in regionData['epapp:tours']"
                 :key="'tour'+key">
          <div class="ov-item ion-text-center ion-activatable ripple-parent ion-padding-bottom"
               :style="{ backgroundColor: tour['epapp:primaryColor'] ? tour['epapp:primaryColor'] : 'var(--ion-color-primary)' }"
               @click="navigate(`/regions/${regionData.id}/tours/${tour['@id']}`)">
            <div class="tour-icon-wrapper">
              <img v-if="tour['schema:logo'] && tour['schema:logo']['schema:url']"
                   :src="media[tour['schema:logo']['schema:url']]" class="tour-icon">
            </div>
            <ion-text class="tour-title">{{ tour['schema:name'] }}</ion-text>
            <ion-ripple-effect></ion-ripple-effect>
          </div>
        </ion-col>
      </ion-row>
      <ion-row v-else>
        <ion-col>
          <p class="ion-text-center">
            {{ $t('tourOverview.noToursAvailable') }}
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>
    </template>
  </basic-page-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonCol, IonRow, IonGrid, IonText, IonRippleEffect } from '@ionic/vue';
import BasicPageLayout from '@/views/basic-page-layout.vue'

export default defineComponent({
  name: 'TourOverviewView',
  extends: BasicPageLayout,
  components: {
    IonCol,
    IonRow,
    IonGrid,
    IonText,
    BasicPageLayout,
    IonRippleEffect
  },

  props: {
    tourColor: {
      type: String,
      default: 'ff0000'
    }
  },

  data() {
    return {};
  },
})
</script>

<style scoped lang="scss">

ion-row {
  padding: 8px 0;
}

ion-col {
  padding: 8px;

  .ov-item {
    min-height: 160px;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    border-radius: 16px;
    color: white;

    .tour-icon-wrapper {
      > img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

</style>
