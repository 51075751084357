
import { defineComponent } from 'vue';
import { IonCol, IonRow, IonGrid, IonText, IonRippleEffect } from '@ionic/vue';
import BasicPageLayout from '@/views/basic-page-layout.vue'

export default defineComponent({
  name: 'TourOverviewView',
  extends: BasicPageLayout,
  components: {
    IonCol,
    IonRow,
    IonGrid,
    IonText,
    BasicPageLayout,
    IonRippleEffect
  },

  props: {
    tourColor: {
      type: String,
      default: 'ff0000'
    }
  },

  data() {
    return {};
  },
})
